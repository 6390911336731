import { theme } from "@/theme";

import Box from "../Box";
import Icon from "../Icon";
import FadeInWhenVisible from "../Transitions/FadeInWhenVisible";

const LeftBarBullet = ({ alignStart, topPosition, pageTheme, secondHeight, icon }: any) => {
  return (
    <Box
      display={{ _: "none", md: "inherit" }}
      p={"4px"}
      height={"100%"}
      position={"absolute"}
      top={topPosition || "unset"}
    >
      <Box pl={"19px"} pr={"19px"} height={alignStart}>
        <Box
          display={"inline-block"}
          height={"100%"}
          width={"3px"}
          backgroundImage={theme.gradients.misc90[5]}
        />
      </Box>

      <Box mt={"24px"} mb={"24px"}>
        <FadeInWhenVisible>
          <Icon
            type={icon ? icon : "Scaled - N - Asterisk"}
            varient={icon ? "solid" : "scaled"}
            size={40}
            color={pageTheme?.headingColor || theme.colors.purple[600]}
            stokeColor={"none"}
          />
        </FadeInWhenVisible>
      </Box>

      <Box pl={"19px"} pr={"19px"} height={"100%"}>
        <Box
          width={"3px"}
          height={secondHeight ? secondHeight : "100%"}
          backgroundImage={theme.gradients.misc90[5]}
        />
      </Box>
    </Box>
  );
};

export default LeftBarBullet;
